import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ajax from '@/api/index'
import stateSession from '../utils/jsonData'
Vue.use(Vuex)
Vue.prototype.$ajax = axios
export default new Vuex.Store({
  state: {
    data: JSON.parse(localStorage.getItem('CHILDREN_DATA')) || stateSession.data
  },
  mutations: {
    setUserToken(state, params) {
      localStorage.clear() // 删除`localStorage`中的所有数据
      state.data = stateSession.data
      state.data.token = params
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    },
    setUserInfo(state, user) {
      console.log('用户信息：', user)
      state.data.userInfo = user
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    },
    setUserMobile(state, mobile) {
      state.data.userInfo.mobile = mobile
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    },
    setHonor(state, params) {
      const list = params.list
      if (list && list.length > 0) {
        list.forEach(element => {
          if (element.medalLogo) element.medalLogo = state.data.imageBaseUsl + element.medalLogo
        })
      }
      if (params.type === 1) state.data.themeMedalList = list
      else if (params.type === 2) state.data.baseMedalList = list
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    },
    setCertTypeList(state, params) {
      state.data.certTypeList = params
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    },
    setHistory(state, params) {
      const info = {
        type: params.type,
        title: params.title,
        path: '/' + params.type + '?k=' + params.title
      }
      let searchData = state.data.searchData
      if (!searchData) searchData = {}
      if (!searchData[info.type]) {
        searchData[info.type] = []
        searchData[info.type].push(info)
      } else if (searchData[info.type].indexOf(info) === -1) {
        console.log('追加历史数据')
        searchData[info.type].unshift(info)
      }
      state.data.searchData = searchData
      localStorage.setItem('CHILDREN_DATA', JSON.stringify(state.data))
    }
  },
  actions: {
    register(context, params) {
      return ajax.register(params)
    },
    bindUser(context, params) {
      return ajax.bindUser(params)
    },
    loginByUsername(context, params) {
      return ajax.loginByUsername(params)
    },
    getUserbindList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getUserbindList(formData)
    },
    loginByMobile(context, params) {
      return ajax.loginByMobile(params)
    },
    appLogout(context, params) {
      return ajax.appLogout(params)
    },
    getUserInfo(context, params) {
      return ajax.getUserInfoByToken(params)
    },
    getUserInfoSession(context, params) {
      return ajax.getUserInfoSession(params)
    },
    updateUserInfo(context, params) {
      return ajax.updateUserInfo(params)
    },
    updateUserMobile(context, params) {
      return ajax.updateUserMobile(params)
    },
    getMyHonorList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getMyHonorList(formData)
    },
    getMyMedalList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getMyMedalList(formData)
    },
    getQueryGuideById() {
      return ajax.queryGuideById()
    },
    getActivityList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getActivityList(formData)
    },
    getActivityMenuList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getActivityMenuList(formData)
    },
    activityOrderCreate(context, params) {
      return ajax.orderCreate(params)
    },
    importTemplate(context, params) {
      return ajax.importTemplate(params)
    },
    orderPrePay(context, params) {
      return ajax.orderPrePay(params)
    },
    coinOrderPayQuery(context, params) {
      return ajax.coinOrderPayQuery(params)
    },
    orderPayQuery(context, params) {
      return ajax.orderPayQuery(params)
    },
    orderModify(context, params) {
      return ajax.orderModify(params)
    },
    orderModifyTeam(context, params) {
      return ajax.orderModifyTeam(params)
    },
    coinOrderPay(context, params) {
      return ajax.coinOrderPay(params)
    },
    getDisciplineList(context, params) {
      return ajax.getDisciplineList(params)
    },
    getDisciplineInfo(context, params) {
      return ajax.queryDisciplineById(params)
    },
    getMyEnrollList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getMyEnrollList(formData)
    },
    getMyEnrollByInfo(context, params) {
      return ajax.getMyEnrollById(params)
    },
    queryScoreByEnrollId(context, params) {
      return ajax.queryScoreByEnrollId(params)
    },
    refundOrder(context, params) {
      return ajax.refundOrder(params)
    },
    getCoinList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getCoinList(formData)
    },
    getCoinTypeList(context, params) {
      return ajax.getCoinTypeList(params)
    },
    getQueryUserAgreementById(context, params) {
      return ajax.queryUserAgreementById(params)
    },
    getQueryPrivacyPolicyById(context, params) {
      return ajax.queryPrivacyPolicyById(params)
    },
    updateUserPassword(context, params) {
      return ajax.updateUserPassword(params)
    },
    getMyEnrollTimeTotal(context, params) {
      return ajax.getMyEnrollTimeTotal(params)
    },
    getQuestionList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getQuestionList(formData)
    },
    getQuestionInfo(context, params) {
      return ajax.getQuestionByInfo(params)
    },
    queryAboutUsById(context, params) {
      return ajax.queryAboutUsById(params)
    },
    getOrderList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getOrderList(formData)
    },
    getOrderInfo(context, params) {
      return ajax.getQueryOrderById(params)
    },
    getQuestionTypeList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.getQuestionTypeList(formData)
    },
    getThemeList(context, params) {
      return ajax.getThemeList(params)
    },
    getAppDictValue(context, params) {
      return ajax.getAppDictValue(params)
    },
    getQueryActivityInfo(context, params) {
      return ajax.getQueryActivityById(params)
    },
    getActivityMenuById(context, params) {
      return ajax.getActivityMenuById(params)
    },
    gethelpCenterList(context, params) {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return ajax.helpCenterList(formData)
    },
    getHelpCenterInfo(context, params) {
      return ajax.getHelpCenterById(params)
    },
    getMobileCode(context, params) {
      return ajax.getMobileCode(params)
    },
    getWxJsddkConfig(context, params) {
      // alert(JSON.stringify(params))
      return ajax.getWxJsddkConfig(params)
    },
    getIsVideoAdvertSuccess(context, params) {
      // alert(JSON.stringify(params))
      return ajax.getIsVideoAdvertSuccess(params)
    },
    getOauthData(context, params) {
      const hostname = encodeURIComponent(window.location.hostname)
      const Kind = params.Kind ? params.Kind : 0
      return ajax.getOauthArgument({
        machineID: params.machineid,
        projectKind: params.event,
        hostname: hostname,
        Kind: Kind
      })
    },
    getUserIntegral(context, params) {
      return ajax.getUserIntegral(params)
    },
    getReadIntegral(context, params) {
      return ajax.getReadIntegral(params)
    },
    getWxUserInfo(context, params) {
      return ajax.getWxUserInfo(params)
    }
  },
  getters: {
    getWechatSignUrl: (state) => state.wxSignUrl
  },
  modules: {

  }
})
